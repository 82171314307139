import { Text } from '@components/ui'
import { twMerge as cn } from 'tailwind-merge'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const Policy = ({ text, className, s = {} }) => {
  const { setHtmlRelativeLink } = useRelativeLink()
  return (
    <div
      className={cn(
        'mt-4 flex items-center justify-center text-[#333]',
        s.wrap,
        className
      )}
    >
      <input
        required
        type="checkbox"
        className={cn(
          'mt-[-2px] h-[18px] w-[18px] cursor-pointer rounded-[3px] border border-[#B7B7B7] bg-white',
          s.input
        )}
      ></input>
      <Text
        html={setHtmlRelativeLink({ html: text })}
        variant="paragraph"
        className={cn('ml-2 [&_a]:underline', s.text)}
      />
    </div>
  )
}

export default Policy
